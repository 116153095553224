<div *ngIf="!showDetails">

  <h6  class="mb-1 p-3" >
    <div class="row">
      <div class="col-1">
        <i
          (click)="back()"
          tooltipPosition="left"
          pTooltip="{{ 'Integration.Zoho.Back' | translate }}"
          class="pi pi-arrow-left mr-1"
        ></i>
      </div>
      <div class="col-9">
        {{title}}
      </div>
      <div class="col-1" >
        <i
          (click)="getRecords(true)"
          tooltipPosition="left"
          pTooltip="{{ 'Integration.Zoho.Update' | translate }}"
          class="pi pi-refresh mr-1"
        ></i>
      </div>
    </div>
  </h6>

  <div *ngIf="!loading && records && records.length > 0" >
    <div (click)="openDetails(record, getModule(record._module))" class="icon-container" *ngFor="let record of records">
      <p-card [style]="{ paddingLeft: '15px', paddingRight: '15px', borderBottom: '1px;' }">
        <div class="flex justify-content-between gap-5">
          <div class="flex flex-column gap-1">
            <p-badge [value]="getModule(record._module)" severity="info" />
              <span class="text-md">  {{ record.Full_Name }} </span>
              <span class="text-secondary text-sm"> {{ record.Company }}</span>
              <span class="text-secondary text-sm"> {{ record.Email }} </span>
          </div>
          <span class="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" >
            <i
              tooltipPosition="left"
              (click)="copy(record.id, record._module)"
              pTooltip="{{ 'Integration.Zoho.Copy link' | translate }}"
              class="pi pi-link
              mr-2 trans"
            ></i>
            <i
              tooltipPosition="left"
              (click)="openOnZoho(record.id, record._module)"
              pTooltip="{{ 'Integration.Zoho.Open on Zoho' | translate }}"
              class="pi pi-external-link mr-2 trans"
            ></i>
          </span>
        </div>
      </p-card>
    </div>
  </div>


  <div *ngIf="!loading && (!records || records.length == 0)" class="card mt-5" style="text-align: center;" >
    <p>  {{ 'Integration.Zoho.No recods found' | translate }} </p>
    <ul id="fullNames" class="list-group list-group-flush">
      <li (click)="createLead()" class="list-group-item list-group-item-action cursor-pointer" >      <i class="pi pi-user-plus mr-1"></i>
        {{ 'Integration.Zoho.Create leads' | translate }}
      </li>
      <li (click)="createContact()" class="list-group-item list-group-item-action cursor-pointer" >
        <i class="pi pi-user-plus mr-1"></i>
        {{ 'Integration.Zoho.Create contact' | translate }}
      </li>
    </ul>
  </div>

  <div *ngIf="loading" class="spinner-border" role="status">
        <span class="visually-hidden"></span>
  </div>

</div>

<div #childDetailsRecord></div>

