import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	inject
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MenuItem } from 'primeng/api';
import { MultiPickerFilter } from 'src/app/shared/interfaces/multi-picker-filter.interface';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { CustomerState } from 'src/app/shared/states/customer.state';
import Swal from 'sweetalert2';
import { ICustomer } from '../../../shared/interfaces/customer.interface';
import { OptionState } from '../../data-access/enum/option-type.enum';
import { WhatsAppMessageStatus } from '../../data-access/enum/whatsapp-message.enum';
import { MessageTransfer } from '../../data-access/interface/message-transfer.interface';
import { TransferMessage } from '../../data-access/interface/transfer-message.interface';
import { Message } from '../../data-access/models/message';
import { getChannelTypeIcon } from '../../utils/chat-method';
@Component({
	selector: 'app-chat-content',
	templateUrl: './chat-content.component.html',

	styleUrls: ['./chat-content.component.scss']
})
export class ChatContentComponent implements OnInit, OnChanges {
	@Input() messages: Message[];
	@Input() groupedMessagesByDate: Message[];
	@Input() fullName: string;
	@Input() phoneNumber: string;
	@Input() businnessPhoneNumber: string;
	@Input() isLoading: boolean = false;
	@Input() changedConversation: boolean = false;
	@Input() serviceInstanceData!: IServiceInstance;
	@Input() setupId: string = '';
	@Input() typeChannel: number = 0;
	@Input() nameChannel: string = '';
	@Input() deliveredMessageId: string | undefined = undefined;

	@Output() messageSended: EventEmitter<Message> = new EventEmitter<Message>();
	@Output() chatClosed: EventEmitter<any> = new EventEmitter<any>();
	@Output() openPanel: EventEmitter<any> = new EventEmitter<any>();
	@Output() loadingMessage: EventEmitter<any> = new EventEmitter<any>();
	@Output() transferMessage: EventEmitter<TransferMessage> = new EventEmitter<TransferMessage>();

	private store = inject(Store);
	private translate = inject(TranslateService);
	private el = inject(ElementRef);

	public profile: ICustomer;
	public scrollUpDistance = 2;
	public scrollDistance = 1;
	public throttle = 300;
	public agents: any[] = [];
	public options: MultiPickerFilter[] = [];
	public optionsAgents: MultiPickerFilter[] = [];
	public optionsBots: MultiPickerFilter[] = [];
	public toTransfer: string = '';

	public itemsMessage: MenuItem[] = [{ items: [{ label: 'message', icon: 'ri-logout-circle-r-line' }] }];

	public isVisibleDialogTransfer = false;
	public classChannel = '';
	public showSearchMessage = false;
	public showInfoContact = false;
	public optionActive: boolean[] = [false, false, false, false];
	public firstTime = true;

	public countMessages = 0;
	private previousMessages: Message[] = [];

	ngOnInit(): void {
		document.querySelector('.user-chat').classList.add('user-chat-show');
		this.profile = this.store.selectSnapshot(CustomerState.getCustomer);

		this.classChannel = getChannelTypeIcon(this.typeChannel);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['messages']) {
			const currentMessages = changes['messages'].currentValue || [];

			if (this.shouldScrollToBottom(this.previousMessages, currentMessages)) {
				setTimeout(() => {
					this.scrollToBottom();
				});
			}

			this.previousMessages = [...currentMessages];
		}

		if (changes['deliveredMessageId'] && this.deliveredMessageId)
			this.updateMessageStatusToDelivered(this.deliveredMessageId);
	}

	private shouldScrollToBottom(previousMessages: any[], currentMessages: any[]): boolean {
		const previousMessagesCount = previousMessages.length;
		const currentMessagesCount = currentMessages.length;

		return (
			this.haveMessagesChanged(previousMessages, currentMessages) || currentMessagesCount === previousMessagesCount + 1
		);
	}

	private haveMessagesChanged(prevMessages: Message[], currMessages: Message[]): boolean {
		if (!prevMessages[0]) return true;

		return prevMessages[0].conversationId !== currMessages[0].conversationId;
	}

	private updateMessageStatusToDelivered(messageId: string): void {
		const message = this.groupedMessagesByDate.find((msg) => msg.id === messageId);

		if (message) message.status = WhatsAppMessageStatus.Delivered;
	}

	scrollToBottom(): void {
		try {
			const scrollContainer = this.el.nativeElement.querySelector('.scroll-custom');
			scrollContainer.scrollTop = scrollContainer.scrollHeight;
		} catch (err) {}
	}

	closeUserChat() {
		document.getElementById('chat-room').classList.remove('user-chat-show');
	}

	onChatInfoClicked() {
		this.openPanel.emit(true);
	}

	// Message Search
	MessageSearch(valueInput: string) {
		var filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;

		filter = valueInput.toUpperCase();
		ul = document.getElementById('users-conversation');
		li = ul.getElementsByTagName('li');
		for (i = 0; i < li.length; i++) {
			a = li[i].getElementsByTagName('p')[0];
			txtValue = a?.innerText;
			if (txtValue?.toUpperCase().indexOf(filter) > -1) {
				li[i].style.display = '';
			} else {
				li[i].style.display = 'none';
			}
		}
	}

	getName(message: Message): string {
		return message.from == this.businnessPhoneNumber ? this.fullName : message.contact;
	}

	receiveMessageSender(messageObj: Message) {
		this.messages.push(messageObj);
		this.groupedMessagesByDate.push(messageObj);
		this.messageSended.emit(messageObj);

		setTimeout(() => {
			this.scrollToBottom();
		});
	}

	endChat() {
		Swal.fire({
			title: this.translate.instant('endChat.title'),
			text: this.translate.instant('endChat.text'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('endChat.confirmButtonText'),
			cancelButtonText: this.translate.instant('endChat.cancelButtonText'),
			customClass: {
				confirmButton: 'btn btn-primary me-4',
				cancelButton: 'btn btn-danger'
			},
			buttonsStyling: false
		}).then((result) => {
			if (result.value) {
				this.chatClosed.emit();
				Swal.fire({
					icon: 'success',
					title: this.translate.instant('endChat.title2'),
					text: this.translate.instant('endChat.text2')
				});
			}
		});
	}

	onScrollUp(event: any) {
		this.loadingMessage.emit({
			conversationId: this.messages[0].conversationId,
			scrollPosition: event.currentScrollPosition
		});
	}

	handleMessageTransfer(object: MessageTransfer) {
		this.isVisibleDialogTransfer = false;
		const body = object.transferAgent
			? {
					text: object.messageTransfer,
					contact: object.toTransfer.label
			  }
			: { Message: object.messageTransfer };

		this.transferMessage.emit({
			idUser: object.toTransfer.value,
			body: JSON.stringify(body),
			adapter: object.transferAgent ? 1 : 2
		});
	}

	handleTransferTo(): void {
		this.toTransfer === 'agent' ? (this.options = this.optionsAgents) : (this.options = this.optionsBots);
	}

	activeOption(position: number): void {
		this.optionActive = this.optionActive.map((_, index) => index === position);

		switch (position) {
			case OptionState.searchMessage:
				this.showSearchMessage = !this.showSearchMessage;
				this.showInfoContact = false;
				break;
			case OptionState.showInfoContact:
				this.showInfoContact = !this.showInfoContact;
				this.showSearchMessage = false;
				break;
			case OptionState.transferChat:
				this.isVisibleDialogTransfer = true;
				this.setOptionsTransferConversation();
				break;
			case OptionState.endChat:
				this.endChat();
				break;
		}
	}

	handleContact(): void {}

	setOptionsTransferConversation(): void {
		this.options = [];
		this.optionsAgents = [];
		this.optionsBots = [];

		const name = this.store.selectSnapshot(CustomerState.getCustomer).given_name;
		this.serviceInstanceData.config.Agents.WhiteList.forEach((agent) => {
			if (agent.Name !== name) this.optionsAgents.push({ label: agent.Name, value: agent.Id });
		});

		this.serviceInstanceData.config.Bots.forEach((bot) => {
			if (!bot.IsStarter) this.optionsBots.push({ label: bot.Name, value: bot.Id });
		});
	}

  getAvatar(): string {
		return `https://i.pravatar.cc/48?u=${this.fullName}`;
	}
}
