import { HttpEventType } from '@angular/common/http';
import { Component, Input, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';
import { WhatsAppMessageStatus } from 'src/app/chat/data-access/enum/whatsapp-message.enum';
import { ICustomerFeature } from 'src/app/chat/data-access/interface/cutomer-feature.interface';
import { Message } from 'src/app/chat/data-access/models/message';
import { MessagingHubService } from 'src/app/chat/data-access/services/messaging-hub.service';

@Component({
	selector: 'app-chat-content-message',
	templateUrl: './chat-content-message.component.html',
	styleUrls: ['./chat-content-message.component.scss']
})
export class ChatContentMessageComponent {
	private _messagingService = inject(MessagingHubService);
	private sanitizer = inject(DomSanitizer);
	private translate = inject(TranslateService);
	private lightbox = inject(Lightbox);

	@Input() message: Message;
	@Input() isFriend: boolean = true;
	@Input() customerFeatureStorage: ICustomerFeature;

	@Input() messageGruop: any;

	public whatsAppMessageStatus = WhatsAppMessageStatus;

	getTime(timestamp: number): string {
		let date = new Date(timestamp);
		let today = new Date();
		let language = this.translate.currentLang + '-' + this.translate.currentLang.toUpperCase();

		const isToday = date.toDateString() === today.toDateString();

		if (isToday) {
			return date.toLocaleTimeString(language, {
				hour: '2-digit',
				minute: '2-digit',
				hour12: true
			});
		}

		return date.toLocaleDateString(language, {
			weekday: 'long',
			hour: '2-digit',
			minute: '2-digit',
			hour12: true
		});
	}

	openImage(): void {
		let _album = [];
		const blobUrl = this.messageGruop.message.url.changingThisBreaksApplicationSecurity;
		const albumItem = { src: blobUrl, caption: 'Imagen Blob', thumb: blobUrl };
		_album.push(albumItem);

		this.lightbox.open(_album, 0, {
			showZoom: true
		});
	}

	downloadFile() {
		const link = document.createElement('a');
		let setups = this.customerFeatureStorage.config;
		this._messagingService
			.downloadFile(this.messageGruop.message.id, this.customerFeatureStorage.featureId, setups.Setups[0].SetupId)
			.subscribe((event) => {
				if (event.type === HttpEventType.Response) {
					this.messageGruop.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
					link.href = this.messageGruop.message.url.changingThisBreaksApplicationSecurity;
					link.download = this.getFileName();
					link.dispatchEvent(new MouseEvent('click'));
				}
			});
	}

	getFileName() {
		if (this.messageGruop.message.filename) return this.messageGruop.message.filename;
		if (this.messageGruop.message.fileName) return this.messageGruop.message.fileName;
		if (this.messageGruop.message.FileName) return this.messageGruop.message.FileName;
	}

	getAvatar(): string {
		return `https://i.pravatar.cc/48?u=${this.messageGruop.contact}`;
	}
}
