import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ZohoCrmBackendService } from '../../../../data-access/zoho-crm-backend.service';

@Component({
  selector: 'app-deals-list',
  templateUrl: './deals-list.component.html',
  styleUrls: ['./deals-list.component.scss']
})
export class DealsListComponent implements OnDestroy, OnInit {

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private subscriptions = new Subscription();
  public recordData?: Map<string, any>;
  public module?: string;
  loading = false;
  deals = [];
  updateCache?: boolean;

  ngOnInit(): void {
    let recordId = '-1';
    if (this.recordData && this.recordData['id']) {
      recordId = this.recordData['id'];
    }
    this.get(recordId, this.updateCache);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get(recordId: string, updateCache: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoCrmBackendService
    .getDeals(recordId, updateCache)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.deals = resp.body;
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
      }, (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }
}
