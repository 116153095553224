import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-record-information',
  templateUrl: './record-information.component.html',
  styleUrls: ['./record-information.component.scss']
})
export class RecordInformationComponent implements OnInit {

  @Input("record") record?: any ;
  @Input("module") module?: string;
  loaded = false;

  // filteredEntries: any[];

  ngOnInit(): void {
    setTimeout(() => {
      // this.filterKeys();
      this.loaded = true;
    });
  }

  /*
  filterKeys() {
    this.filteredEntries = Object
    .entries(JSON.parse(JSON.stringify(this.record)))
    .filter(([key, value]) => !key.startsWith('$'));
  }
    */

  //  && !this.show(key) && !key.includes('Time'))

  show(key: string): boolean {
    return (
      key === 'id' ||
      key === 'Full_Name' ||
      key === 'Mobile' ||
      key === 'Email' ||
      key === 'Owner' ||
      key === 'Modified_By' ||
      key === 'Created_By'
    );
  }

  formatLabel(key: string): string {
    console.error(key);
    return key;
    // return key.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim();
  }

  getValue(key: string): any {
    return this.record[key];
  }

}
