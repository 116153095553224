<ul *ngIf="!loading && activities && activities.length > 0" class="list-group list-group-flush mt-2">
  <li *ngFor="let activity of activities" class="list-group-item mb-2">
    <div class="row">
      <div class="col-2">
        <i style="font-size: 1.5rem" class="pi" ngClass="{{ getIcon(activity.type) }}"></i>
      </div>
      <div class="col-10">
        <p style="font-weight: bold;">{{ activity.title }}</p>
        {{ activity.createdBy }}
        <br />
        {{ activity.created }}
      </div>
    </div>
</ul>

<div *ngIf="!loading && (!activities || activities.length == 0)" class="card mt-5" style="text-align: center;" >
  <p>  {{ 'Integration.Zoho.There are no activities' | translate }} </p>
  <ul id="fullNames" class="list-group list-group-flush">
    <li (click)="createTask()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-file-check mr-1"></i>
      {{ 'Integration.Zoho.New task' | translate }}
    </li>
    <li (click)="createEvent()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-calendar-clock mr-1"></i>
      {{ 'Integration.Zoho.New event' | translate }}
    </li>
    <li (click)="createCall()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-phone mr-1"></i>
      {{ 'Integration.Zoho.New call' | translate }}
    </li>
  </ul>
</div>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>
