<h6  class="mb-1 p-3" >
  <div class="row">
    <div class="col-1">
      <i
        tooltipPosition="left"
        (click)="back()"
        pTooltip="{{ 'Integration.Zoho.Back' | translate }}"
        class="pi pi-arrow-left mr-1"
      ></i>
    </div>
    <div class="col-9">
      {{title}}
    </div>
    <div class="col-1" >
      <app-ticket-filter
         (filterEmiter)="filter($event)"
      ></app-ticket-filter>
    </div>
    <div class="col-1" >
      <i
        tooltipPosition="left"
        (click)="update()"
        pTooltip="{{ 'Integration.Zoho.Update' | translate }}"
        class="pi pi-refresh mr-1"
      ></i>
    </div>
  </div>
</h6>

<div *ngIf="!loading && tickets && tickets.length > 0" >
  <div (click)="openDetails(ticket)" class="icon-container" *ngFor="let ticket of tickets">

        <p-card [style]="{ paddingLeft: '15px', paddingRight: '15px', borderBottom: '1px;' }">
          <div class="flex justify-content-between gap-5">
              <div class="flex flex-column gap-1">
                  <span class="text-md">{{  ticket.subject }}</span>
                  <span *ngIf="ticket.contact" class="text-secondary text-sm"> {{ ticket.contact.firstName }} {{ ticket.contact.lastName }}</span>
                  <span class="text-secondary text-sm">{{ ticket.createdTimeFormated }} <span *ngIf="ticket.isDue" style="color: rgb(229, 62, 62);">({{ticket.dueDays}} days past due)</span></span>
              </div>
              <span class="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" >
                <i
                  tooltipPosition="left"
                  (click)="copy(ticket.id)"
                  pTooltip="{{ 'Integration.Zoho.Copy link' | translate }}"
                  class="pi pi-link
                  mr-2 trans"
                ></i>
                <i
                  tooltipPosition="left"
                  (click)="openOnZoho(ticket.id)"
                  pTooltip="{{ 'Integration.Zoho.Open on Desk' | translate }}"
                  class="pi pi-external-link mr-2 trans"
                ></i>
              </span>
          </div>
          <div class="row">
            <div class="col-2">
              <div class="col-md-6-custom text-left">
                <span class="text-sm"> #{{ ticket.ticketNumber }}</span>
              </div>
            </div>
            <div class="col-4">
             <p-badge [value]="ticket.status" severity="{{ getSeverity(ticket) }}" />
             <i
               tooltipPosition="left"
               pTooltip="{{ticket.dueDays}} days past due"
                *ngIf="ticket.isDue"
                class="pi pi-hourglass ml-1"
                style="color: rgb(229, 62, 62);"
              ></i>
            </div>
            <div class="col-6" style="text-align: right;">
              <i class="pi pi-headphones mr-1"></i>
              <span *ngIf="!ticket.assignee" class="text-secondary text-sm"> {{ 'Integration.Zoho.Unassigned' | translate }}</span>
              <span *ngIf="ticket.assignee" class="text-secondary text-sm"> {{ ticket.assignee.firstName }} {{ ticket.assignee.lastName }}</span>
            </div>
          </div>
      </p-card>
      </div>
    </div>


<div *ngIf="!loading && (!tickets || tickets.length == 0)" class="card mt-5" style="text-align: center;" >
  <p>  {{ 'Integration.Zoho.No tickets found' | translate }} </p>
  <ul id="fullNames" class="list-group list-group-flush">
    <li style="cursor: pointer;" (click)="newTicket()" class="list-group-item list-group-item-action" >
      <i
        tooltipPosition="left"
        pTooltip="{{ 'Integration.Zoho.Create ticket' | translate }}"
        class="pi pi-plus-circle mr-1"
      ></i>
      {{ 'Integration.Zoho.Create ticket' | translate }}
    </li>
  </ul>
</div>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>

