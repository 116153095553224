
<h6  class="mb-1 p-3" >
  <div class="row">
    <div class="col-1">
      <i
        (click)="back()"
        pTooltip="{{ 'Integration.Zoho.Back' | translate }}"
        class="pi pi-arrow-left mr-1"
        tooltipPosition="left"
      ></i>
    </div>
    <div class="col-9">
      {{ 'Integration.Zoho.Configuration' | translate }}
    </div>
  </div>
</h6>

<div class="p-2">
  <div>
    <h6>{{ 'Integration.Zoho.Select Layouts' | translate }}</h6>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="col-md-12">
        <div class="row">
          <div class="col-6" *ngFor="let key of getValues()">
            <div *ngIf="layoutResponse[key]" >
              <h6> {{ 'Integration.Zoho.' + key | translate }}</h6>
              <div formGroupName="{{ key }}">
                <div *ngFor="let option of layoutResponse[key]">
                  <p-checkbox
                    [formControlName]="option.name"
                    label="{{ option.name }}"
                    [binary]="true"
                    (click)="updateSelection(key, option.name)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-3 mr-2">
        <p-inputSwitch
          formControlName="showOnlyRequiredFields"
          (click)="updateShowOnlyRequiredFields($event)"
          class="ng-dirty ng-invalid"
          inputId="chkshowOnlyRequiredFields"
        />
        <label class="ml-3"  for="chkshowOnlyRequiredFields">
          {{ 'Integration.Zoho.Only show required fields on forms' | translate }}
        </label>
      </div>

      <div class="flex justify-content-center flex-wrap align-items-center mt-5">
        <p-button label="{{ 'Integration.Zoho.' + getButtonTitle() | translate }}" icon="pi pi-refresh" size="small" class="mr-2"
          type="submit" [disabled]="sending" />
      </div>

    </form>
  </div>

  <hr />

  <div class="flex justify-content-center flex-wrap align-items-center mt-5">
    <p-button
      label="{{ 'Integration.Zoho.Log out Zoho' | translate }}"
      icon="pi pi-power-off"
      size="small"
      class="mr-2"
      severity="danger"
      (onClick)="confirm()"
    />

    <p-confirmDialog
        key="positionDialog"
        [position]="'right'"
        rejectButtonStyleClass="p-button-outlined" >
        <ng-template pTemplate="message" let-message>
          <div class="custom-dialog-content">
              {{ message.message }}
          </div>
      </ng-template>
    </p-confirmDialog>
  </div>

</div>
