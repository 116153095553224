<h6 class="mb-1 p-3">
  <div class="row">
    <div class="col-11">
      <span *ngIf="module === 'LEADS'">{{ 'Integration.Zoho.Lead Details' | translate }}</span>
      <span *ngIf="module === 'CONTACTS'">{{ 'Integration.Zoho.Contact Details' | translate }}</span>
    </div>
  </div>
</h6>

<table *ngIf="loaded" class="table-tickets-details">
  <tr>
    <td class="row-details tlabel">ID de registro</td>
    <td class="tvalue">{{ record.id }}</td>
  </tr>
  <tr>
    <td class="row-details tlabel">Nombre completo</td>
    <td class="tvalue">{{ record.Full_Name }}</td>
  </tr>
  <tr>
    <td class="row-details tlabel">Mobile</td>
    <td class="tvalue">{{ record.Mobile }}</td>
  </tr>
  <tr>
    <td class="row-details tlabel">Email</td>
    <td class="tvalue">{{ record.Email }}</td>
  </tr>
  <tr>
    <td class="row-details tlabel">Hora de Modificacion</td>
    <td class="tvalue">{{ record.modifiedTimeAsString }}</td>
  </tr>
  <tr>
    <td class="row-details tlabel">Hora de Creacion</td>
    <td class="tvalue">{{ record.createdTimeAsString }}</td>
  </tr>
  <tr>
    <td class="row-details tlabel">Hora de la ultima actividad</td>
    <td class="tvalue">{{ record.lastActivityTimeAsString }}</td>
  </tr>
  <tr>
    <td class="row-details tlabel">Owner</td>
    <td class="tvalue"><span *ngIf="record.Owner">{{ record.Owner.name }}</span></td>
  </tr>
  <tr>
    <td class="row-details tlabel">Modified By</td>
    <td class="tvalue"><span *ngIf="record.Modified_By">{{ record.Modified_By.name }}</span></td>
  </tr>
  <tr>
    <td class="row-details tlabel">Created By</td>
    <td class="tvalue"><span *ngIf="record.Created_By">{{ record.Created_By.name }}</span></td>
  </tr>

  <!--
  <tr *ngFor="let key of filteredEntries">
    <td class="row-details tlabel">{{ formatLabel(key) }}</td>
    <td class="tvalue">{{ getValue(key) }}</td>
  </tr>
  -->

</table>
