<li class="chats" [ngClass]="{ right: !isFriend }">
	<div *ngIf="!messageGruop.haveDate" class="conversation-list">
		<div class="chat-avatar">
			<div class="avatar-sm">
				<span *ngIf="isFriend" class="avatar-title rounded-circle bg-primary-subtle text-primary">
					<img [src]="getAvatar()" alt="" />
				</span>

				<span *ngIf="!isFriend" class="avatar-title rounded-circle bg-primary-subtle text-primary">
					{{ messageGruop.contact.charAt(0) }}
				</span>
			</div>
		</div>

		<div class="user-chat-content">
			<div class="ctext-wrap">
				<div class="ctext-wrap-content">
					<p *ngIf="this.messageGruop.messageType === 0" class="mb-0 messageText">
						{{ messageGruop.message }}
					</p>

					<div *ngIf="this.messageGruop.messageType === 2" class="card p-2 mb-2">
						<app-image-message
							[mimeType]="messageGruop.message.mime_type"
							[fileName]="getFileName()"
							(emitClick)="downloadFile()"
						>
						</app-image-message>
					</div>

					<div *ngIf="this.messageGruop.messageType === 3">
						<video [src]="this.messageGruop.message.url" class="card-img-top" height="180" controls></video>
					</div>

					<div *ngIf="this.messageGruop.messageType === 4 && messageGruop.message.url">
						<audio controls class="custom-audio">
							<source [src]="this.messageGruop.message.url" />
						</audio>
					</div>
					<ul
						*ngIf="this.messageGruop.messageType === 8 || this.messageGruop.messageType === 5"
						class="list-inline message-img mb-0"
					>
						<li class="list-inline-item message-img-list">
							<div>
								<a class="popup-img d-inline-block m-1 p-0" href="javascript:void(0);" title="Project 1">
									<img [src]="messageGruop.message.url" alt="" class="rounded border" (click)="openImage()" />
								</a>
							</div>
						</li>
					</ul>
					<div class="chat-time mb-0">
						<span class="align-middle">{{ getTime(messageGruop.timeStamp) }}</span>
						<div *ngIf="!isFriend" class="material-symbols-outlined icon" [ngSwitch]="messageGruop.status">
							<span *ngSwitchCase="whatsAppMessageStatus.Sending">schedule</span>
							<span *ngSwitchCase="whatsAppMessageStatus.Sent">done_all</span>
							<span *ngSwitchCase="whatsAppMessageStatus.Delivered">done_all</span>
						</div>

					</div>
				</div>
			</div>
			<div class="conversation-name">
				<span>{{ messageGruop.contact }}</span>
			</div>
		</div>
	</div>
	<div class="chat-day-title" *ngIf="messageGruop.haveDate">
		<span class="title">{{ messageGruop.date }}</span>
	</div>
</li>
