  <div class="container-body mt-3">
    <div class="card">
      <div class="card-header">
        {{ 'Integration.Zoho.contacts' | translate }}
      </div>
      <ul id="fullNames" class="list-group list-group-flush">
        <li style="cursor: pointer;" *ngFor="let contact of contacts" class="list-group-item list-group-item-action" (click)="selected(contact)">
           {{ contact.firstName }} {{ contact.lastName }}
        </li>
      </ul>
    </div>
  </div>
