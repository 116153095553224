<div class="pt-4">

  <div style="text-align: right;" class="flex-wrap mb-3">

      <p-button
        (click)="createActivity('tasks')"
        icon="pi pi-file-check"
        [rounded]="true"
        [text]="true"
         severity="secondary"
         class="mr-1"
         pTooltip="{{ 'Integration.Zoho.New task' | translate }}"
        tooltipPosition="left"
      />

      <p-button
        (click)="createActivity('events')"
        icon="pi pi-calendar-clock"
        [rounded]="true"
        [text]="true"
        severity="secondary"
        class="mr-1"
        pTooltip="{{ 'Integration.Zoho.New event' | translate }}"
        tooltipPosition="left"
      />

      <p-button
        (click)="createActivity('calls')"
        icon="pi pi-phone"
        [rounded]="true"
        [text]="true"
        severity="secondary"
        class="mr-1"
        pTooltip="{{ 'Integration.Zoho.New call' | translate }}"
        tooltipPosition="left"
      />

  </div>

<div #childActivitiesComponents></div>

</div>
