
<div class="row mt-1">
  <div class="col-1 pl-4">
    <i tooltipPosition="left"  pTooltip="{{ 'Integration.Zoho.Exit' | translate }}"
    style="float: left;" (click)="back()" class="pi pi-times cursor-pointer"></i>
  </div>
  <div class="col-10 flex justify-content-center">
    <img class="mr-2" src="assets/images/integrations/Zoho Desk.svg" width="18" style="margin-top: -5px;" />
    <h5>Zoho Desk </h5>
    <div class="ml-2">
      <i tooltipPosition="left"  pTooltip="{{ 'Integration.Zoho.Configure and sync Layouts' | translate }}" style="cursor: pointer"
    (click)="startIntegrationConfig()" class="pi pi-cog mr-1"></i>
    </div>
  </div>
  <div class="col-1"></div>
</div>
<hr class="header" />

  <div class="flex justify-content-center mb-2">
    <div class="dropdown-wrapper dw-width-1" >
      <p-dropdown
        [options]="departments"
        [(ngModel)]="currentDepartmentId"
        optionLabel="name"
        optionValue="id"
        (onChange)="updateDepartment()"
        [loading]="loadingDepartment"
      />
    </div>
  </div>

  <div class="row ml-2 mr-2">
    <div class="col-5 dropdown-wrapper" style="padding-right: 0;">
      <p-dropdown
        [disabled]="loadingDepartment"
        id="dropdown"
        [options]="options"
        [(ngModel)]="selectedSearchOption"
        (onChange)="chanceTooltip()"
      >
      </p-dropdown>
    </div>
    <div class="col-7 input-wrapper" style="padding-left: 0;">
      <input
        class="w-full"
        pInputText
        id="input"
        [(ngModel)]="searchInput"
        placeholder="{{ 'Integration.Zoho.' + placeHolder | translate }}"
        (change)="search()"
        [disabled]="loadingDepartment"
        pTooltip="{{ 'Integration.Zoho.' + placeHolder | translate }}"
        tooltipPosition="left"
      />
    </div>
  </div>

  <div #childZohoDeskComponents></div>
  <!-- Dinamic components below -->
