import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/integrations/zoho/utils/messages.service';
import { ZohoCrmBackendService } from '../../../data-access/zoho-crm-backend.service';

@Component({
  selector: 'app-record-notes',
  templateUrl: './record-notes.component.html',
  styleUrls: ['./record-notes.component.scss']
})
export class RecordNotesComponent implements OnInit {

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private messageService = inject(MessageService);

  private subscriptions = new Subscription();

  noteForm: FormGroup;
  savingNote: boolean;
  loadingNotes = false;
  notes: any[] = [];
  noteContent = '';
  show = false;

  @Input("record") record?: any ;
  @Input("module") module?: string;

  ngOnInit(): void {
    setTimeout(() => {
      this.getNotes(false);
    });
  }

  getNotes(update: boolean): void {
    this.loadingNotes = true ;
    const getNotes$ = this._ZohoCrmBackendService
    .getNotes(this.module, this.record.id, update)
    .subscribe(
        (resp: HttpResponse<any[]>) => {
            if (resp.status == 200) {
              this.notes = resp.body;
              this.loadingNotes = false ;
            }
            this.subscriptions.add(getNotes$);
        },
        (error: HttpErrorResponse) => console.error(error));
	}

  addNote(): void {
    if (this.noteContent === '') {
      this.messageService.showMessage('You must enter a comment', 'warning', false);
    } else {
      const saveNote$ = this._ZohoCrmBackendService
        .saveNote({
          noteContent: this.noteContent,
          recordId: this.record.id,
          moduleZohoCrmType: this.module.toUpperCase(),
        })
        .subscribe(() => {
            this.subscriptions.add(saveNote$);
            this.show = false;
            this.getNotes(true);
        },
        (error: HttpErrorResponse) => {
          this.savingNote = false;
          console.error(error);
        });
    }
	}

  showForm(): void {
    this.show = true;
  }


}
