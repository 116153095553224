import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Channel } from 'src/app/chat/data-access/enum/channel-type.enum';
import { MessageType } from 'src/app/chat/data-access/interface/message.interface';
import { Message } from 'src/app/chat/data-access/models/message';

@Component({
	selector: 'app-chat-conversation-item',
	templateUrl: './chat-conversation-item.component.html',
	styleUrls: ['./chat-conversation-item.component.scss']
})
export class ChatConversationItemComponent {
	@Input() conversationId: string = '';
	@Input() fullname: string = '';
	@Input() phoneNumber: string = '';
	@Input() lastMessage: Message;
	@Input() timestamp: number = 0;
	@Input() unSeenMessages: number = 0;
	@Input() channel: number = 0;
	@Input() nameChannel: string = '';

	@Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();

	private translate = inject(TranslateService);

	sendConversation(event: any) {
		this.removeClass();
		this.addClass(event);
		event.preventDefault();
		this.dataChanged.emit({ conversationId: this.conversationId, phoneNumber: this.phoneNumber });
	}

	removeClass(): void {
		var removeClass = document.querySelectorAll('.chat-user-list li');
		removeClass.forEach((element: any) => {
			element.classList.remove('active');
		});
	}

	addClass(event: any): void {
		event.target.closest('li').classList.add('active');
	}

	getTime(timeStamp: number): string {
		const now = new Date();
		const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

		const date = new Date(timeStamp);
		const isToday =
			date.getFullYear() === today.getFullYear() &&
			date.getMonth() === today.getMonth() &&
			date.getDate() === today.getDate();

		const isYesterday =
			date.getFullYear() === yesterday.getFullYear() &&
			date.getMonth() === yesterday.getMonth() &&
			date.getDate() === yesterday.getDate();

		let language = this.translate.currentLang + '-' + this.translate.currentLang.toUpperCase();
		if (isToday) {
			const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true } as const;
			return date.toLocaleTimeString(language, timeOptions);
		} else if (isYesterday) {
			return 'Yesterday';
		}

		const dateOptions = { day: 'numeric', month: 'short', year: 'numeric' } as const;

		return date.toLocaleDateString(language, dateOptions);
	}

	getChannelTypeIcon(): string {
		switch (this.channel) {
			case Channel.WhatsApp:
				return 'ri-whatsapp-line';
			case Channel.Telegram:
				return 'ri-telegram-line';
			case Channel.Messenger:
				return 'ri-messenger-line';
			case Channel.MessagingHub:
				return 'ri-message-3-line';
			case Channel.Bot:
				return 'ri-robot-line';
			case Channel.Email:
				return 'ri-mail-line';
			default:
				return 'ri-whatsapp-line';
		}
	}

	getMessageType(): MessageType {
		return this.lastMessage.messageType;
	}

	isMessageText(): boolean {
		return this.getMessageType() === MessageType.Text;
	}

	getMessageTypeText(): string {
		switch (this.getMessageType()) {
			case MessageType.Document:
				return 'File';
			case MessageType.Video:
				return 'Video';
			case MessageType.Audio:
				return 'Play';
			case MessageType.Sticker:
				return 'Sticker';
			default:
				return 'Photo';
		}
	}

	getMessageTypeIconClass(): string {
		switch (this.getMessageType()) {
			case MessageType.Document:
				return 'ri-file-line';
			case MessageType.Video:
				return 'ri-video-line';
			case MessageType.Audio:
				return 'ri-mv-line';
			default:
				return 'ri-image-line';
		}
	}

	getTruncatedMessage(): string {
		if (this.lastMessage.message.length > 40) {
			return this.lastMessage.message.substring(0, 20) + '...';
		} else {
			return this.lastMessage.message;
		}
	}

	getAvatar(): string {
		return `https://i.pravatar.cc/48?u=${this.fullname}`;
	}
}
