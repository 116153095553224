import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { emitSelectedValues, MultiPickerFilterI } from '../../interfaces/multi-picker-filter.interface';

@Component({
	selector: 'app-bar-search',
	templateUrl: './bar-search.component.html',
	styleUrls: ['./bar-search.component.scss']
})
export class BarSearchComponent implements OnInit {
	@Input() options: MultiPickerFilterI[] = [
		{
			value: 'Filter by channel',
			label: 'Filter by channel',
			type: 'check',
			children: [
				{ value: 'Whatsapp', label: 'Whatsapp' },
				{ value: 'Telegram', label: 'Telegram' }
			]
		}
	];

	@Input() placeholder: string = 'chatComponent.SearchHere';
	@Input() numberFilters = 0;

	@Output() emitValue: EventEmitter<string> = new EventEmitter<string>();
	@Output() emitValues: EventEmitter<emitSelectedValues> = new EventEmitter<emitSelectedValues>();

	private elementRef = inject(ElementRef);

	public dynamicForm!: FormGroup;

	public inputValue: string = '';
	public isFilter: boolean = false;
	public isViewFilter: boolean = false;
	public showChildren: boolean = false;
	public showCategory: boolean = false;
	public showOptions: boolean = false;
	public trueCounts: number[] = [];
	public selectedCategory: any = null;
	public categoryType: string = '';
	public isSelection: boolean = false;

	private filters: any = {};

	ngOnInit(): void {
		this.dynamicForm = this.createFormGroup();

		this.dynamicForm.valueChanges.subscribe((values: any) => {
			this.trueCounts = [];
			Object.values(values).forEach((element: any) => {
				this.trueCounts.push(this.countTrueValues(element));
				this.numberFilters = this.trueCounts.reduce((total, current) => total + current, 0);
			});
		});
	}

	createFormGroup(): FormGroup {
		const group: any = {};

		this.options.forEach((category) => {
			const categoryGroup: any = {};
			category.children.forEach((child: any) => {
				categoryGroup[child.value] = new FormControl(category.type === 'check' ? false : '');
			});
			group[category.value] = new FormGroup(categoryGroup);
		});

		return new FormGroup(group);
	}

	countTrueValues(data: any): number {
		return Object.values(data).reduce((acc: number, value: any) => {
			if (Array.isArray(value)) {
				return acc + value.filter((v) => v === true).length;
			} else if (value === true) {
				return acc + 1;
			} else {
				return acc;
			}
		}, 0) as number;
	}

  clearFilters(): void {
    Object.keys(this.dynamicForm.controls).forEach((categoryKey: string) => {
      const categoryGroup = this.dynamicForm.get(categoryKey) as FormGroup;
      Object.keys(categoryGroup.controls).forEach((controlKey: string) => {
        categoryGroup.get(controlKey)?.setValue(false);
      });
    });
  }


	handleOptionsClick(event: MouseEvent) {
		event.stopPropagation();
	}

	handleFieldKeyUp(event: string): void {
		this.filters['search'] = event;
		this.emitValue.emit(this.filters);
	}

	handleFilter(): void {
		this.isViewFilter = !this.isViewFilter;
		this.showOptions = !this.showOptions;
	}

	viewOptionsChildren(category: any) {
		this.categoryType = category.type;
		this.selectedCategory = category;
		this.showCategory = true;
		this.showChildren = true;
	}

	backToParentOption() {
		this.selectedCategory = null;
		this.showChildren = false;
	}

	onSubmit() {
		this.showOptions = false;
		this.isFilter = false;
		this.isViewFilter = false;

		this.isSelection = true;
		this.backToParentOption();
		this.filters['filter'] = this.groupByParameter(this.prepareData(this.dynamicForm.value));
		this.emitValue.emit(this.filters);
	}

	prepareData(valueForm: any): any {
		const filteredOptions: { value: string | number; name: string }[] = this.options.flatMap((option) =>
			option.children
				.filter((child) => {
					const childValue = child.value;
					return valueForm[option.value][childValue as string | number];
				})
				.map((child) => ({ parameter: option.value, value: child.value, name: child.label }))
		);

		return filteredOptions;
	}

	groupByParameter(data: any): any {
		return data.reduce((result: any, item: any) => {
			if (!result[item.parameter]) {
				result[item.parameter] = [];
			}
			result[item.parameter].push(item.value);
			return result;
		}, {});
	}

	handleClose(): void {
		this.isFilter = false;
		this.isViewFilter = false;
		this.isSelection = false;
	}
}
