<li (click)="sendConversation($event)">
	<a href="javascript:void(0);">
		<div class="flex gap-2">
			<div class="chat-avatar">
        <div class="avatar-sm">
          <span class="avatar-title rounded-circle bg-primary-subtle text-primary">
            <img [src]="getAvatar()" alt="" />
          </span>
        </div>
      </div>
			<div class="flex justify-content-between w-100">
				<div class="container-conversation">
					<div class="d-flex align-items-center gap-1">
						<h5 class="name mb-0">{{ fullname }}</h5>
					</div>

					<div class="">
						<div class="primary-badge">
							<i [ngClass]="getChannelTypeIcon()"></i>
							{{ nameChannel }}
						</div>
					</div>

					<p *ngIf="isMessageText()" class="message mb-0">{{ getTruncatedMessage() }}</p>

					<div *ngIf="!isMessageText()" class="d-flex align-items-center">
						<i [ngClass]="getMessageTypeIconClass()" class="feather text-muted icon-md"></i>
						<p class="text-muted mb-0">{{ getMessageTypeText() }}</p>
					</div>
				</div>
				<div class="container-date-unread-message">
					<div class="time">{{ getTime(timestamp) }}</div>
					<span *ngIf="unSeenMessages !== 0" class="badge-unread-message">{{ unSeenMessages }}</span>
				</div>
			</div>
		</div>
	</a>
</li>
