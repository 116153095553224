<h6  class="mb-1 p-3" >
  <div class="row">
    <div class="col-11">
      <span>{{ 'Integration.Zoho.Notes' | translate }}</span>
    </div>
    <div class="col-1">
      <i
      (click)="showForm()"
      tooltipPosition="left"
      pTooltip="{{ 'Integration.Zoho.new note' | translate }}"
      class="pi pi-plus mr-1"
    ></i>
    </div>
  </div>
</h6>

<div *ngIf="show">
  <div class="mb-3">
    <label for="exampleFormControlTextarea1" class="form-label">
      {{ 'Integration.Zoho.new note' | translate }}
    </label>
    <textarea
      pInputTextarea
      [(ngModel)]="noteContent"
      class="form-control"
      rows="3"
      [disabled]="savingNote"
    >
    </textarea>
  </div>

  <div class="flex justify-content-center flex-wrap align-items-center">
    <p-button
      label="{{ 'Integration.Zoho.add' | translate }}"
      icon="pi pi-plus"
      size="small"
      class="mr-2"
      type="submit"
      [disabled]="savingNote"
      (onClick)="addNote()"
    />
  </div>
</div>

<div *ngIf="loadingNotes" class="spinner-border mt-5" role="status">
  <span class="visually-hidden"></span>
</div>

<div *ngIf="!loadingNotes && notes && notes.length > 0" class="list-group list-group-flush mt-2">
  <div *ngFor="let note of notes">
    <p-card [style]="{ paddingLeft: '15px', paddingRight: '15px', borderBottom: '1px;' }">
      <div class="flex justify-content-between gap-5">
        <div class="flex flex-column gap-1">
            <span class="text-md">{{  note.content }}</span>
            <span *ngIf="note.owner" class="text-secondary text-sm"> {{ note.owner }}</span>
            <span class="text-secondary text-sm">{{ note.createAt }}</span>
        </div>
      </div>
    </p-card>
  </div>
</div>

<span *ngIf="!loadingNotes && (!notes || notes.length == 0)" class="mt-2">
  <div *ngIf="!show" class="card mt-5" style="text-align: center;" >
    <p> {{ 'Integration.Zoho.No notes found' | translate }} </p>
    <ul id="fullNames" class="list-group list-group-flush">
      <li style="cursor: pointer;" (click)="showForm()" class="list-group-item list-group-item-action" >
        <i
          tooltipPosition="left"
          pTooltip="{{ 'Integration.Zoho.new notet' | translate }}"
          class="pi pi-plus-circle mr-1"
        ></i>
        {{ 'Integration.Zoho.new note' | translate }}
      </li>
    </ul>
  </div>
</span>
