<i class="material-icons-outlined">perm_contact_calendar</i>

<div *ngIf="showCard" class="options-contact custom-scrollbar swing-in-top-fwd" (click)="$event.stopPropagation()">
	<div class="search-i"></div>
	<div class="flex flex-row align-items-center gap-2">
		<div class="avatar-sm">
			<span class="avatar-title rounded-circle bg-primary-subtle text-primary">
				{{ fullName.charAt(0) }}
			</span>
		</div>

		<div class="flex flex-column gap-2">
			<div class="container-name">
				<span class="fullName">{{ fullName }}</span>
				<div class="badge-black flex-row">
					<i class="material-icons-outlined text-base">chat</i>
					<span class="conversetions-open">3 conversations opened</span>
				</div>
			</div>
		</div>
	</div>
	<div class="phone-container">
		<i class="material-icons-outlined text-base">phone</i>
		{{ phoneNumber }}
	</div>
	<div *ngIf="isExistContact" class="phone-container">
		<i class="material-icons-outlined text-base">email</i>
		{{ email }}
	</div>
	<p-button *ngIf="isExistContact" class="w-100" (click)="emitValue.emit()">
		<div class="custom-btn">
			<i class="material-icons-outlined icon">remove_red_eye</i>
			<span class="label">{{ 'View contact' | translate }}</span>
		</div>
	</p-button>

	<p-button *ngIf="!isExistContact" class="w-100" (click)="emitValue.emit()">
		<div class="custom-btn">
			<i class="material-icons-outlined icon">group_add</i>
			<span class="label">{{ 'Add contact' | translate }}</span>
		</div>
	</p-button>
</div>
