<div *ngIf="!showEditRecord">

  <h6 class="mb-1 ml-2 mr-2 p-2">
    <div class="row">
      <div class="col-1">
        <i (click)="back()" tooltipPosition="left"  pTooltip="{{ 'Integration.Zoho.Back' | translate }}" class="pi pi-arrow-left mr-1"></i>
      </div>
      <div class="col-8" style="padding: 0 !important;">
        <p-avatar label="{{getInitials(record['Full_Name'])}}" styleClass="mr-2"
          [style]="{ 'background-color': '#dee9fc', color: '#1a2551' }" />
        <span pTooltip="{{record['Full_Name']}}" tooltipPosition="left" >
          {{ cutName(record['Full_Name']) }}
        </span>
      </div>
      <div class="col-1">
        <i tooltipPosition="left"  (click)="openOnZohoCRM(record['id'])" pTooltip="{{ 'Integration.Zoho.Open on Desk' | translate }}"
          class="pi pi-external-link mr-2 trans"></i>
      </div>
      <div class="col-1">
        <i tooltipPosition="left"  (click)="updateRecord(true)" pTooltip="{{ 'Integration.Zoho.Update' | translate }}"
          class="pi pi-refresh mr-1"></i>
      </div>
      <div class="col-1">
        <i tooltipPosition="left"  (click)="editRecord()" pTooltip="{{ 'Integration.Zoho.edit' | translate }}" class="pi pi-pencil mr-1"></i>
      </div>
    </div>
  </h6>

  <div *ngIf="loaded && !updating" class="card ml-2 mr-2">
    <p-tabView [style]="{ marginTop: '10px;'}">

      <p-tabPanel>
        <ng-template pTemplate="header">
          <i tooltipPosition="left"  pTooltip="{{ 'Integration.Zoho.Information' | translate }}" class="pi pi-th-large"></i>
        </ng-template>
        <app-record-information [module]="module" [record]="record"></app-record-information>
      </p-tabPanel>


      <p-tabPanel>
        <ng-template pTemplate="header">
          <i tooltipPosition="left"  pTooltip="{{ 'Integration.Zoho.Notes' | translate }}" class="pi pi-clipboard"></i>
        </ng-template>
        <app-record-notes [module]="module" [record]="record"></app-record-notes>
      </p-tabPanel>

      <p-tabPanel *ngIf="module === 'CONTACTS'">
        <ng-template pTemplate="header">
          <i tooltipPosition="left"  pTooltip="{{ 'Integration.Zoho.Opportunities' | translate }}" class="pi pi-check-square"></i>
        </ng-template>
        <app-zohocrm-deals [record]="record" [module]="module"
          (emitAction)="emitOthersAction($event)"></app-zohocrm-deals>
      </p-tabPanel>

      <p-tabPanel>
        <ng-template pTemplate="header">
          <i tooltipPosition="left"  pTooltip="{{ 'Integration.Zoho.Activities' | translate }}" class="pi pi-list-check"></i>
        </ng-template>
        <app-zohocrm-activities [module]="module" [record]="record"
          (emitAction)="emitOthersAction($event)"></app-zohocrm-activities>
      </p-tabPanel>

    </p-tabView>
  </div>

  <div *ngIf="updating" class="spinner-border mt-5" role="status">
    <span class="visually-hidden"></span>
  </div>

</div>

<div *ngIf="showEditRecord">
  <h6 class="mb-1 ml-2 mr-2 p-2">
    <div class="row">
      <div class="col-1">
        <i tooltipPosition="left"  (click)="backEdit()" pTooltip="{{ 'Integration.Zoho.Back' | translate }}" class="pi pi-arrow-left mr-1"></i>
      </div>
      <div class="col-8">
        <span tooltipPosition="left"  pTooltip="Edit {{module}}">
          Edit {{module}}
        </span>
      </div>
    </div>
  </h6>
</div>
<div #childEditRecord></div>
